@font-face {
  font-family: "menulab-font";
  src: url("fonts/menulab-font.eot?dwh1xa");
  src: url("fonts/menulab-font.eot?dwh1xa#iefix") format("embedded-opentype"),
    url("fonts/menulab-font.woff2?dwh1xa") format("woff2"),
    url("fonts/menulab-font.ttf?dwh1xa") format("truetype"),
    url("fonts/menulab-font.woff?dwh1xa") format("woff"),
    url("fonts/menulab-font.svg?dwh1xa#menulab-font") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "menulab-font" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-qr-code:before {
  content: "\e919";
}
.icon-brush-solid:before {
  content: "\e914";
}
.icon-copy-content:before {
  content: "\e915";
}
.icon-copy-paste:before {
  content: "\e916";
}
.icon-duplicate:before {
  content: "\e92b";
}
.icon-crayon-1:before {
  content: "\e929";
}
.icon-crayon-2:before {
  content: "\e92a";
  margin-left: -1em;
}
.icon-drink50cl:before {
  content: "\e925";
  
}
.icon-drink25cl:before {
  content: "\e926";
  
}
.icon-drink75cl:before {
  content: "\e927";
  
}
.icon-drink37\,5cl:before {
  content: "\e928";
  
}

.icon-drink15cl:before {
  content: "\e924";
  
}
.icon-phone1:before {
  content: "\e922";
}
.icon-phone2:before {
  content: "\e923";
}
.icon-chevron:before {
  content: "\e921";
}
.icon-facebookk1:before {
  content: "\e91a";
  color: rgb(63, 81, 181);
}
.icon-facebookk2:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-google1:before {
  content: "\e91c";
  color: rgb(255, 193, 7);
}
.icon-google2:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(255, 61, 0);
}
.icon-google3:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(76, 175, 80);
}
.icon-google4:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}
.icon-apple:before {
  content: "\e920";
}
.icon-info:before {
  content: "\e918";
}
.icon-brush-fill:before {
  content: "\e917";
}
.icon-arrow-down:before {
  content: "\e912";
}
.icon-arrow-up:before {
  content: "\e913";
}
.icon-facebook:before {
  content: "\e910";
  color: #5a3615;
}
.icon-instagram:before {
  content: "\e911";
  color: #5a3615;
}
.icon-burger:before {
  content: "\e90f";
}
.icon-loupe:before {
  content: "\e90e";
}
.icon-italic:before {
  content: "\e909";
}
.icon-font-family:before {
  content: "\e90a";
}
.icon-color-picker:before {
  content: "\e90b";
}
.icon-letter-spacing:before {
  content: "\e90c";
}
.icon-bold:before {
  content: "\e90d";
}
.icon-settings:before {
  content: "\e900";
}
.icon-view:before {
  content: "\e901";
}
.icon-edit:before {
  content: "\e902";
}
.icon-move:before {
  content: "\e903";
}
.icon-trash:before {
  content: "\e904";
}
.icon-add:before {
  content: "\e905";
}
.icon-valide:before {
  content: "\e906";
}
.icon-add-picture:before {
  content: "\e907";
}
.icon-user:before {
  content: "\e908";
}
