@keyframes animation {
  from {
    row-gap: 0rem;
    column-gap: 0rem;
  }
  to {
    row-gap: 1.25rem;
    column-gap: 3.25rem;
  }
}

@keyframes reverseAnimation {
  from {
    row-gap: 1.25rem;
    column-gap: 3.25rem;
  }
  to {
    row-gap: 0rem;
    column-gap: 0rem;
  }
}

.transition {
  animation-name: animation;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  transform: translateY(-50%);
  transition: all 0.3s;
}

.reverseTransition {
  animation-name: reverseAnimation;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  transition: all 0.3s;
}

@keyframes fontSizeUp {
  from {
    font-size: 0px;
  }
  to {
    font-size: 30px;
  }
}

@keyframes fontSizeDown {
  from {
    font-size: 30px;
  }
  to {
    font-size: 0px;
  }
}

.fontSizeUp {
  animation-name: fontSizeUp;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.fontSizeDown {
  animation-name: fontSizeDown;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

@keyframes squareUp {
  from {
    width: 0px;
    height: 0px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  }
  to {
    width: 50px;
    height: 50px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
  }
}

@keyframes squareDown {
  from {
    width: 50px;
    height: 50px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
  }
  to {
    width: 0px;
    height: 0px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  }
}

.squareUp {
  animation-name: squareUp;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.squareDown {
  animation-name: squareDown;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

@keyframes containerHeightUp {
  from {
    height: 0px;
  }
  to {
    height: 300px;
  }
}

@keyframes containerHeightDown {
  from {
    height: 300px;
  }
  to {
    height: 0px;
  }
}

.containerHeightUp {
  animation-name: containerHeightUp;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.containerHeightDown {
  animation-name: containerHeightDown;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}
