.rightBorderDotted {
  border-right: 1px dotted black;
  border-left: 1px dotted black;
}

th:last-child {
  border-right: none;
}

th:first-child {
  border-left: none;
}

@media screen and (min-width: 768px) {
  .rightBorderDotted {
    border-right: 1px dotted black;
  }

  th:last-child {
    border-right: none;
  }
}

.mystyle {
  background-color: red;
}
